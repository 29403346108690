<template>
  <transition-group name="rank-list-top" tag="ul" className="top">
    <li v-for="rank in ranks.slice(0, 3)" :key="rank.name" class="rank-item">
      <span class="rank">{{ rank.rank }}</span> {{ rank.name }}
      <span class="score">{{ rank.win }} - {{ rank.loss }}</span>
    </li>
  </transition-group>
  <transition-group name="rank-list-bottom" tag="ul" className="bottom">
    <li v-for="rank in ranks.slice(3, 10)" :key="rank.name" class="rank-item">
      <span class="rank">{{ rank.rank }}</span> {{ rank.name }}
      <span class="score">{{ rank.win }} - {{ rank.loss }}</span>
    </li>
  </transition-group>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      polling: null,
    };
  },
  computed: mapState({
    ranks: (state) => state.rankings.all,
  }),
  methods: {
    pollData() {
      this.polling = setInterval(() => {
        this.$store.dispatch("rankings/getAllRankings");
      }, 3000);
    },
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  created() {
    this.pollData();
  },
};
</script>

<style scoped>
.rank-item {
  display: block;
  list-style: outside none none;
  position: relative;
  text-transform: uppercase;
  text-align: left;
  font-size: 3.25rem;
  padding: 1rem;
  margin: 1rem;
}

ul {
  display: block;
  width: 46%;
  overflow: hidden;
  float: left;
}

ul.top li {
  font-size: 9.5rem;
  padding: 0;
}

ul.top li .score {
  font-size: 2rem;
  color: #908fa0;
}

ul li .score {
  font-size: 1rem;
  position: absolute;
  bottom: 0px;
  left: 12px;
  color: #908fa0;
  font-family: "Roboto", sans-serif;
}

ul li span.rank {
  color: rgb(255, 128, 128);
  font-weight: bold;
}

.rank-list-top-move {
  transition: transform 0.5s ease;
}

.rank-list-bottom-move {
  transition: transform 0.5s ease;
}

.rank-list-bottom-active {
  animation: bounce-in 0.5s;
}
.rank-list-top-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
</style>