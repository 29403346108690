import rankings from '../../api/rankings'

// initial state
const state = () => ({
    all: []
})

// getters
const getters = {}

// actions
const actions = {
    async getAllRankings({ commit }) {
        const ranks = await rankings.get()
        commit('setRankings', ranks)
    }
}

// mutations
const mutations = {
    setRankings(state, ranks) {
        state.all = ranks
    },

    decrementProductInventory(state, { id }) {
        const product = state.all.find(product => product.id === id)
        product.inventory--
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}