<template>
  <div class="container">
    <lottie-player
      src="https://assets5.lottiefiles.com/packages/lf20_LwuNNj/data.json"
      background="transparent"
      speed="1"
      style="width: 300px; height: 300px"
      loop
      autoplay
    ></lottie-player>
    <RankList />
    <div class="ball"></div>
  </div>
</template>

<script>
//import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import RankList from "./components/RankList.vue";

export default {
  name: "App",
  components: {
    RankList,
    //LottieAnimation,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: "Oswald", sans-serif;
}

lottie-player {
  position: absolute;
  right: 0;
  bottom: 0;
}
.container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.container .ball {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgb(255, 128, 128);
  position: absolute;
  -webkit-animation: moveX 3.05s linear 0s infinite alternate,
    moveY 4.2s linear 0s infinite alternate;
  -moz-animation: moveX 3.05s linear 0s infinite alternate,
    moveY 4.2s linear 0s infinite alternate;
  -o-animation: moveX 3.05s linear 0s infinite alternate,
    moveY 4.2s linear 0s infinite alternate;
  animation: moveX 4s linear 0s infinite alternate,
    moveY 4.2s linear 0s infinite alternate;
  z-index: 99999;
}
@-webkit-keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
@-moz-keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
@-o-keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
@keyframes moveX {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}
@-webkit-keyframes moveY {
  from {
    top: 0;
  }
  to {
    top: 920px;
  }
}
@-moz-keyframes moveY {
  from {
    top: 0;
  }
  to {
    top: 920px;
  }
}
@-o-keyframes moveY {
  from {
    top: 0;
  }
  to {
    top: 920px;
  }
}
@keyframes moveY {
  from {
    top: 0;
  }
  to {
    top: 920px;
  }
}
</style>
