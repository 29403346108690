import axios from "axios"

export default {
    async get() {
        let rankings;
        await axios
            .get('https://script.googleusercontent.com/macros/echo?user_content_key=0lfzE32Ze-LgMt46bZycbrm9C1U5gz1lu2MWdm88nPFkyrJ_Mozfvt5kBaAbQALkw3U6HZhMN8ifoxBLC4aA3Czy45TR3NAPm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnCcgwTrFkMlMGlbTnwzSZlhDLYFqcqAj62PdxSEmlsU0-uPFlt5yXX-eIQ9MgjPSx_CbW21eGBuwDWXLYy4PhDY2F_X37AQc-dz9Jw9Md8uu&lib=MBsUnk7gO3kqKTXIcC4jYq7_dWKuDqPRL')
            .then(response => (rankings = response.data.rankings))
            .catch(error => console.log(error))
            .finally(() => this.loading = false)
        console.log(rankings)
        return rankings;
    }
}